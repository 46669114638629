import React, { useEffect } from 'react';

import {
  Switch,
  Route,
  useLocation
} from 'react-router-dom';

// import {Elements} from '@stripe/react-stripe-js';
// import {loadStripe} from '@stripe/stripe-js';

import './css/style.scss';

import AOS from 'aos';
import { focusHandling } from 'cruip-js-toolkit';

import Home from './pages/Home';
import Portfolio from './pages/Portfolio'
import Features from './pages/Features';
import Pricing from './pages/Pricing';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import About from './pages/About';
import Contact from './pages/Contact';
import Help from './pages/Help';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import ResetPassword from './pages/ResetPassword';
import PageNotFound from './pages/PageNotFound';
import WheelOnePage from './pages/WheelOnePage';
import Process from './pages/Process';
// import CheckoutForm from './partials/CheckoutForm';
import ProductCupOne from './pages/ProductCupOne';
import ProductShifterOne from './pages/ProductShifterOne';

// const stripePromise = loadStripe('pk_test_51JqWsrJomLXxTXpANarvyGSqiFJMITovikbYn85WtrwUimSuAB6RCdm8Au8UVGeM2274Qs9FmdheHg7APztJKjWs00mkUJJMIe');
// just a publishable test key :)


function App() {
  // const options = {
    // passing the client secret obtained from the server
    // clientSecret: '{{CLIENT_SECRET}}',
  // };

  const location = useLocation();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 600,
      easing: 'ease-out-sine',
    });
  });

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
    focusHandling('outline');
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/theProcess">
          <Process />
        </Route>
        <Route path="/features">
          <Features />
        </Route>
        <Route path="/pricing">
          <Pricing />
        </Route>
        <Route path="/blog">
          <Blog />
        </Route>
        <Route path="/blog-post">
          <BlogPost />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/help">
          <Help />
        </Route>
        <Route path="/signin">
          <SignIn />
        </Route>
        <Route path="/signup">
          <SignUp />
        </Route>
        <Route path="/reset-password">
          <ResetPassword />
        </Route>
        <Route path='/portfolio' component={() => { 
          window.location.href = 'https://loroadracingshare.notion.site/Mechanical-Design-Engineering-Portfolio-Josh-Lo-5a2c397268974dca9d433a60ff7e0c18'; 
        return null;
        }}/>
       <Route path='/resumeLink' component={() => { 
          window.location.href = 'https://pacecarsbucket.s3.us-west-2.amazonaws.com/Joshua+Lo+-+Software+Engineering+Resume+April+2024.pdf'; 
          return null;
        }}/>
        {/* Product Page Routes */}
        <Route path="/LRRGT-Wheel">
          <WheelOnePage />
        </Route>
        <Route path="/Holey-Cup"> 
          <ProductCupOne />
        </Route>
        <Route path="/Seqy-Shifter">
          <ProductShifterOne />
        </Route>
        {/* Route for hidden pages */}
        <Route path="/Portfolio">
          <Portfolio />
        </Route>
        {/* Footer Routes */}
        <Route path='/linkedInnyPoo' component={() => { 
          window.location.href = 'https://www.linkedin.com/company/lo-road-racing-llc'; 
        return null;
        }}/>
        <Route path='/igLink' component={() => { 
          window.location.href = 'https://www.instagram.com/joshlrr/'; 
        return null;
        }}/>
        <Route path='/gitLink' component={() => { 
          window.location.href = 'https://github.com/JoshLRR'; 
        return null;
        }}/>
        <Route path="*">
          <PageNotFound />
        </Route>


      </Switch>
      {/* <Elements stripe={stripePromise} options={options}>
        <CheckoutForm /> 
      </Elements> */}
    </>
  );
}

export default App;
