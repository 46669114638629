import React from 'react';
import Palouse from '../images/Palouse.jpg';

import Header from '../partials/Header';
import Footer from '../partials/Footer';

function Portfolio() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}

      {/* Background Image */}
      <div className="absolute inset-0">
        <img className="w-full h-full object-cover" src={Palouse} width="1440" height="394" alt="About" />
        <div className="absolute inset-0 bg-gray-900 opacity-75" aria-hidden="true"></div>
      </div>

      <main className="flex-grow">


      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default Portfolio;