import React from 'react';

import MeAndNando from '../images/meAndNando.jpg';
import TacoFace from '../images/TacoFace2.jpg';
import NandoFace from '../images/NandoFace3.jpg';
import LewisFace from '../images/LewisFace2.jpg';

function Team() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 mb-4">Our passionate team</h2>
            <p className="text-xl text-gray-400">Professional poopers</p>
          </div>

          {/* Team members */}
          <div className="sm:flex sm:flex-wrap sm:justify-center -my-4 sm:-my-8 sm:-mx-3" data-aos-id-team>

            {/* 1st member */}
            <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3" data-aos="fade-up" data-aos-anchor="[data-aos-id-team]">
              <div className="flex flex-col items-center">
                <img className="rounded-full mb-4" src={MeAndNando} width="120" height="120" alt="Team member 01" />
                <h4 className="text-xl font-medium mb-1">Josh Lo</h4>
                <div className="text-gray-500 mb-1">CEO & Founder</div>
                <a className="block text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="#0">@JoshLRR</a>
              </div>
            </div>

            {/* 2nd member */}
            <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3" data-aos="fade-up" data-aos-delay="100" data-aos-anchor="[data-aos-id-team]">
              <div className="flex flex-col items-center">
                <img className="rounded-full mb-4" src={TacoFace} width="120" height="120" alt="Team member 02" />
                <h4 className="text-xl font-medium mb-1">Taco Lo aka Pastor Mal-Dog-Nado</h4>
                <div className="text-gray-500 mb-1">Head of Emotional Support & CEO of Taco corp.</div>
                <a className="block text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="#0">@TacoIsAGoodBoi</a>
              </div>
            </div>

            {/* 3rd member */}
            <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3" data-aos="fade-up" data-aos-delay="200" data-aos-anchor="[data-aos-id-team]">
              <div className="flex flex-col items-center">
                <img className="rounded-full mb-4" src={NandoFace} width="120" height="120" alt="Team member 03" />
                <h4 className="text-xl font-medium mb-1">Fur-Nando Alons-Lo</h4>
                <div className="text-gray-500 mb-1">Head of Good Bois</div>
                <a className="block text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="#0">@ProfessionalDog</a>
              </div>
            </div>

            {/* 4th member */}
            <div className="sm:w-1/2 md:w-1/3 lg:w-1/4 py-4 sm:py-8 sm:px-3" data-aos="fade-up" data-aos-delay="300" data-aos-anchor="[data-aos-id-team]">
              <div className="flex flex-col items-center">
                <img className="rounded-full mb-4" src={LewisFace} width="120" height="120" alt="Team member 04" />
                <h4 className="text-xl font-medium mb-1">Lewis Ham-Lo-Ton</h4>
                <div className="text-gray-500 mb-1">Head of Tyre Loss</div>
                <a className="block text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out" href="#0">@theNewDog</a>
              </div>
            </div>

          </div>

        </div>
      </div>
    </section>
  );
}

export default Team;