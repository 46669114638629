import { StarIcon } from '@heroicons/react/solid'
import { RadioGroup } from '@headlessui/react'
import React, { useState } from 'react';

// import Modal from '../utils/Modal';

const cupPage = {
  name: 'TRYPOPHOBIA CUP', 
  price: '42069 DOLLARS', // get value from db eventually
  href: '#',
  breadcrumbs: [
    { id: 1, name: 'Products', href: '#' },
    { id: 2, name: 'Sub Category', href: '#' },
  ],
  images: [
    {
      src: 'https://pacecarsbucket.s3.us-west-2.amazonaws.com/sunny+boi.png', 
      alt: 'Simracing on the beach.',
    },
    {
      src: 'https://pacecarsbucket.s3.us-west-2.amazonaws.com/CuppaRender1.PNG',
      alt: 'Noice Renda',
    },
    {
      src: 'https://pacecarsbucket.s3.us-west-2.amazonaws.com/AssemblyRender.png',
      alt: 'Rig mockup',
    },
    {
      src: "https://pacecarsbucket.s3.us-west-2.amazonaws.com/WheelRender5.png",
      alt: 'bwaaaaahhhhhhh bwaaaaAAAAHHHHHhHHHH BWAAAAAHHHHHHHHH',
    },
  ],
  colors: [
    { name: 'White', class: 'bg-white', selectedClass: 'ring-gray-400' },
    { name: 'Gray', class: 'bg-gray-200', selectedClass: 'ring-gray-400' },
    { name: 'Black', class: 'bg-gray-900', selectedClass: 'ring-gray-900' },
    { name: 'GrayText', class: 'text-gray-400', selectedClass: 'ring-gray-400'}
  ],
  sizes: [
    { name: 'FA', inStock: false },
    { name: 'CA', inStock: true },
    { name: 'MK', inStock: true },

  ],
  description:
    'Thirty years ago, Niki Lauda told us \'take a monkey, place him into the cockpit and he is able to drive the car. \' Thirty years later, Sebastian told us \'I had to start my car like a computer, it\'s very complicated\'......”',
  highlights: [
    'Organic and free-range racing wheel',
    'Business in the front, party in the back. Illuminate your sim cockpit with rear-facing LEDs shone through a clear polycarbonate layer -- with tuneable settings to race in style, your way.',
    'Precision machined components using 3-axis and 4-axis toolpaths where applicable',
    'Durable direct-injected silicone grips',
  ],
  details:
    'This wheel is LRR\'s first entry into the simracing industry. ',
}
const reviews = { href: '#', average: 4.2, totalCount: 69420 }

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function CupOneProduct() {
  // const [selectedColor, setSelectedColor] = useState(cupPage.colors[0])
  const [selectedSize, setSelectedSize] = useState(cupPage.sizes[2])

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 pointer-events-none">
        <div className="py-12 md:py-20">
          <nav aria-label="Breadcrumb">
            <li role="list" className="max-w-2xl mx-auto px-4 flex items-center space-x-2 sm:px-6 lg:max-w-7xl lg:px-8">
              {cupPage.breadcrumbs.map((breadcrumb) => (
                <span key={breadcrumb.id}>
                  <div className="flex items-center">
                    <a href={breadcrumb.href} className="mr-2 text-sm font-medium text-gray-400">
                    {breadcrumb.name}
                    </a>
                    <svg
                      width={16}
                      height={20}
                      viewBox="0 0 16 20"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      className="w-4 h-5 text-gray-300"
                    >
                      <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                    </svg>
                  </div>
                </span>
             ))}
            <span className="text-sm">
              <a href={cupPage.href} aria-current="page" className="font-medium text-gray-500 hover:text-gray-400">
                {cupPage.name}
              </a>
            </span>
          </li>
        </nav>

        {/* Image gallery */}
        <div className="mt-6 max-w-2xl mx-auto sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
          <div className="hidden aspect-w-3 aspect-h-4 rounded-lg overflow-hidden lg:block" data-aos="fade-right">
            <img
              src={cupPage.images[0].src}
              alt={cupPage.images[0].alt}
              className="w-full h-full object-center object-cover"
            />
          </div>
          <div className="hidden lg:grid lg:grid-cols-1 lg:gap-y-8">
            <div className="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden" data-aos="fade-right">
              <img
                src={cupPage.images[1].src}
                alt={cupPage.images[1].alt}
                className="w-full h-full object-center object-cover"
              />
            </div>
            <div className="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden" data-aos="fade-up">
              <img
                src={cupPage.images[2].src}
                alt={cupPage.images[2].alt}
                className="w-full h-full object-center object-cover"
              />
            </div>
          </div>
          <div className="aspect-w-4 aspect-h-5 sm:rounded-lg sm:overflow-hidden lg:aspect-w-3 lg:aspect-h-4" data-aos="fade-up">
            <img
              src={cupPage.images[3].src}
              alt={cupPage.images[3].alt}
              className="w-full h-full object-center object-cover"
            />
          </div>
        </div>

        {/* Product info */}
        <div className="max-w-2xl mx-auto pt-10 pb-16 px-4 sm:px-6 lg:max-w-7xl lg:pt-16 lg:pb-24 lg:px-8 lg:grid lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8">
          <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
            <h1 className="text-2xl font-extrabold tracking-tight text-gray-400 sm:text-3xl">{cupPage.name}</h1>
          </div>

          {/* Options */}
          <div className="mt-4 lg:mt-0 lg:row-span-3">
            <h2 className="sr-only">Product information</h2>
            <p className="text-3xl text-gray-400">{cupPage.price}</p>

            {/* Reviews */}
            <div className="mt-6">
              <h3 className="sr-only">Reviews</h3>
              <div className="flex items-center">
                <div className="flex items-center">
                  {[0, 1, 2, 3, 4].map((rating) => (
                    <StarIcon
                      key={rating}
                      className={classNames(
                        reviews.average > rating ? 'text-gray-400' : 'text-gray-200',
                        'h-5 w-5 flex-shrink-0'
                      )}
                      aria-hidden="true"
                    />
                  ))}
                </div>
                <p className="sr-only">{reviews.average} out of 5 stars</p>
                <a href={reviews.href} className="ml-3 text-sm font-medium text-indigo-600 hover:text-indigo-500">
                  {reviews.totalCount} reviews
                </a>
              </div>
            </div>

            <form className="mt-10">
              {/* Colors */}
              {/* <div>
                <h3 className="text-sm text-gray-400 font-medium">Color</h3>

                <RadioGroup value={selectedColor} onChange={setSelectedColor} className="mt-4">
                  <RadioGroup.Label className="sr-only">Choose a color</RadioGroup.Label>
                  <div className="flex items-center space-x-3">
                    {cupPage.colors.map((color) => (
                      <RadioGroup.Option
                        key={color.name}
                        value={color}
                        className={({ active, checked }) =>
                          classNames(
                            color.selectedClass,
                            active && checked ? 'ring ring-offset-1' : '',
                            !active && checked ? 'ring-2' : '',
                            '-m-0.5 relative p-0.5 rounded-full flex items-center justify-center cursor-pointer focus:outline-none'
                          )
                        }
                      >
                        <RadioGroup.Label as="p" className="sr-only">
                          {color.name}
                        </RadioGroup.Label>
                        <span
                          aria-hidden="true"
                          className={classNames(
                            color.class,
                            'h-8 w-8 border border-black border-opacity-10 rounded-full'
                          )}
                        />
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
              </div> */}

              {/* Sizes */}
              <div className="mt-10">
                <div className="flex items-center justify-between">
                  <h3 className="text-sm text-gray-400 font-medium">Kit selection</h3>
                  {/* eslint-disable-next-line*/}
                  <a to="/pricing" href="/pricing" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
                    Kit guide
                  </a>
                </div>

                <RadioGroup value={selectedSize} onChange={setSelectedSize} className="mt-4">
                  <RadioGroup.Label className="sr-only">Choose a kit</RadioGroup.Label>
                  <div className="grid grid-cols-4 gap-4 sm:grid-cols-8 lg:grid-cols-4">
                    {cupPage.sizes.map((size) => (
                      <RadioGroup.Option
                        key={size.name}
                        value={size}
                        disabled={!size.inStock}
                        className={({ active }) =>
                          classNames(
                            size.inStock
                              ? 'bg-white shadow-sm text-gray-400 cursor-pointer'
                              : 'bg-gray-50 text-gray-200 cursor-not-allowed',
                            active ? 'ring-2 ring-indigo-500' : '',
                            'group relative border rounded-md py-3 px-4 flex items-center justify-center text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1 sm:py-6'
                          )
                        }
                      >
                        {({ active, checked }) => (
                          <>
                            <RadioGroup.Label as="p">{size.name}</RadioGroup.Label>
                            {size.inStock ? (
                              <div
                                className={classNames(
                                  active ? 'border' : 'border-2',
                                  checked ? 'border-indigo-500' : 'border-transparent',
                                  'absolute -inset-px rounded-md pointer-events-none'
                                )}
                                aria-hidden="true"
                              />
                            ) : (
                              <div
                                aria-hidden="true"
                                className="absolute -inset-px rounded-md border-2 border-gray-200 pointer-events-none"
                              >
                                <svg
                                  className="absolute inset-0 w-full h-full text-gray-200 stroke-2"
                                  viewBox="0 0 100 100"
                                  preserveAspectRatio="none"
                                  stroke="currentColor"
                                >
                                  <line x1={0} y1={100} x2={100} y2={0} vectorEffect="non-scaling-stroke" />
                                </svg>
                              </div>
                            )}
                          </>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
              </div>

              <button
                type="submit"
                className="mt-10 w-full bg-indigo-600 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Add to bag
              </button>
            </form>
          </div>

          <div className="py-10 lg:pt-6 lg:pb-16 lg:col-start-1 lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
            {/* Description and details */}
            <div>
              <h3 className="sr-only">Description</h3>

              <div className="space-y-6">
                <p className="text-base text-gray-400">{cupPage.description}</p>
              </div>
            </div>

            <div className="mt-10">
              <h3 className="text-sm font-medium text-gray-200">Highlights</h3>
              <div className="mt-4">
                <span role="list" className="pl-4 list-disc text-sm space-y-2">
                  {cupPage.highlights.map((highlight) => (
                    <li key={highlight} className="text-gray-400">
                      <span className="text-gray-420">{highlight}</span>
                    </li>
                  ))}
                </span>
              </div>
            </div>

            <div className="mt-10">
              <h2 className="text-sm font-medium text-gray-400">Details</h2>

              <div className="mt-4 space-y-6">
                <p className="text-sm text-gray-420">{cupPage.details}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    </section>
      )
}

